.register-page {
    display: flex;
    justify-content: center;
    margin: 50px 0px;

    .card {
        width: 100%;
        padding: 20px 40px 60px 40px;
        max-width: 840px;
    }

    .main-heading {
        text-align: center;
        margin: 20px 0px 40px 0px;
    }

    .submit {
        justify-content: center;

        button {
            color: #fff;
            background-color: #545b62;
            border-color: #545b62;
            margin: 10px 20px;
        }

    }

    .forgot-pass-links {
        margin: 20px 0px;
        text-align: center !important;
    }
}